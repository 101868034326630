@font-face {
  font-family: "AppRegular";
  src: url("./assets/fonts/SFPRODISPLAYREGULAR.OTF");
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "AppRegular";
}

body {
  font-family: "AppRegular";
}

.scroll-link {
  /* Define your passive link styles here */
  color: #fff;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.scroll-link.active {
  /* Define your active link styles here */
  color: #fff;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration-color: #fff;
}

.carousel-transition-enter {
  opacity: 0;
  transform: translateX(100%);
}

.carousel-transition-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 500ms, transform 500ms;
}

.carousel-transition-exit {
  opacity: 1;
  transform: translateX(0%);
}

.carousel-transition-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms, transform 500ms;
}

@keyframes moveDownAnimation {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.animated-icon {
  animation: moveDownAnimation 1s ease-in-out infinite alternate;
  color: #fff;
}
